import {gql} from "@apollo/client";

export const RETRIEVE_TIME_SERIES = gql`
    query metrics {
        retrieveTimeSeries {
            points
            minimumPoints
            availablePoints
            timeSeries {
                createdAt
                availablePoints
                minimumPoints
                points
                conversation {
                  leadType              
                }
            }
        }
    }
`;