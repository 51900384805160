import { FC, PropsWithChildren } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Icon } from "@iconify/react";
import "./index.scss";

interface HeaderProps {
  title: string;
  showIntro?: boolean;
  noPadding?: boolean;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  title,
  showIntro = true,
  children,
  noPadding = false,
}) => {
  const router = useIonRouter();

  return (
    <IonHeader className="ion-no-border header">
      <IonToolbar className={"ion-no-padding toolbar"}>
        <IonGrid>
          <div className={"header-content"}>
            <IonRow className={"ion-justify-content-between"}>
              <IonCol size="auto" className={"ion-padding"}>
                <img
                  src="/assets/imgs/logo/logo-white.png"
                  height={36}
                  alt=""
                />
              </IonCol>
              {showIntro && (
                <IonButtons className={"ion-padding"}>
                  <IonButton
                    onClick={() => router.push("/intro", "back", "push")}
                    routerDirection="none"
                  >
                    <Icon
                      color={"white"}
                      width={24}
                      height={24}
                      icon={"material-symbols:info-outline"}
                    />
                  </IonButton>
                </IonButtons>
              )}
            </IonRow>
            <h6 className={"ion-text-center"}>{title}</h6>
          </div>
          <div className={!noPadding ? "ion-padding" : ""}>{children}</div>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};
