import React, { FC, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { User } from "../../hooks/useUsers";
import { Icon } from "@iconify/react";
import { useMutation } from "@apollo/client";
import { VERIFY_USER } from "../../../../schemas/users";

export const VerifyUser: FC<{ onClose: () => void; user: User | null }> = ({
  onClose,
  user,
}) => {
  const [verifyUserMutation, { error }] = useMutation(VERIFY_USER);
  const [loading, setLoading] = useState<boolean>(false);
  const [present] = useIonToast();

  const verifyUser = () => {
    setLoading(true);
    // verify user mutation
    verifyUserMutation({ variables: { userId: user?.id } }).then(async () => {
      setLoading(false);
      await present({
        message: "Du hast den Benutzer erfolgreich verifiziert!",
        color: "success",
        duration: 1500,
        position: "bottom",
      });
      onClose();
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen className="modaaal">
        <IonGrid className="main-grid">
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <div className="the-holder ion-padding">
              <IonRow className="title-row">
                <IonCol
                  size="12"
                  class="ion-text-right"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <p>abbrechen</p>
                </IonCol>
              </IonRow>
              <h4 className={"ion-justify-content-center ion-text-center"}>
                Bist du dir sicher den Benutzer manuell zu verifizieren ?
              </h4>
              <p className={"ion-text-center"}>
                <b>Benutzer:</b> {user?.email}
              </p>
              <IonRow className={"ion-justify-content-center ion-padding"}>
                <Icon width={"84px"} height={"84px"} icon="ph:check-bold" />
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-padding"}>
                <IonButton onClick={verifyUser} disabled={loading}>
                  {loading ? (
                    <IonSpinner name="circular"></IonSpinner>
                  ) : (
                    "Verifizieren"
                  )}
                </IonButton>
              </IonRow>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
