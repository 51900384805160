import { gql } from "@apollo/client";
export const UPDATE_PROFILE = gql`
  mutation updateUser(
    $laborname: String
    $firstname: String
    $lastname: String
    $email: String
    $password: String
    $password_confirm: String
  ) {
    updateUser(
      data: {
        laborname: $laborname
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
        password_confirm: $password_confirm
      }
    ) {
      id
      email
      firstname
      lastname
      laborname
      verifiedAt
      introDoneAt
      role
    }
  }
`;

export const GET_PROFILE = gql`
  query profile {
    me {
      id
      email
      firstname
      lastname
      laborname
      gender
      role
      verifiedAt
      introDoneAt
    }
  }
`;
