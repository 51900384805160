import React, { FC, useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";

import "./Signup.scss";
import { checkmarkCircle, eye, eyeOff } from "ionicons/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import UIContext from "../../providers/UIProvider";
import { useMutation } from "@apollo/client";
import { SIGNUP } from "../../schemas/signup";
import { useAuth } from "../../providers/auth";
import { Preferences } from "@capacitor/preferences";

const Signup: FC = () => {
  const { setShowFab } = useContext(UIContext);
  const [signup, { error: signupError }] = useMutation(SIGNUP);
  const { setAuthToken, setUser } = useAuth();
  const router = useIonRouter();
  const [present] = useIonToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    setShowFab(false);
  }, []);

  const initialValues = {
    email: "",
    password: "",
    password_confirm: "",
    laborname: "",
    firstname: "",
    lastname: "",
    gender: "",
  };

  const validationSchema = yup.object({
    laborname: yup
      .string()
      .trim()
      .required("Bitte gib einen Labornamen ein.")
      .min(3, "Der Laborname sollte mindestens 3 Zeichen lang sein."),
    firstname: yup
      .string()
      .trim()
      .required("Bitte gib einen Vornamen ein.")
      .min(3, "Der Vorname sollte mindestens 3 Zeichen lang sein."),
    lastname: yup
      .string()
      .trim()
      .required("Bitte gib einen Nachnamen ein.")
      .min(3, "Der Nachname sollte mindestens 3 Zeichen lang sein."),
    email: yup
      .string()
      .trim()
      .email("Bitte gib eine gültige E-Mail Adresse ein.")
      .required("Bitte gib eine E-Mail Adresse ein."),
    password: yup
      .string()
      .required("Bitte gib dein Passwort ein.")
      .min(5, "Das Passwort sollte mindestens 5 Zeichen lang sein"),
    password_confirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Die Passwörter stimmen nicht überein."
      ),
    gender: yup.string().required("Bitte wähle dein Geschlecht."),
  });

  const onSubmit: any = async (
    values: any,
    { setStatus, setErrors, errors, status }: any
  ) => {
    setLoading(true);

    const result = signup({ variables: { ...values } })
      .then(async (result) => {
        setLoading(false);

        if (result?.data?.signup?.accessToken) {
          setAuthToken(result.data.signup.accessToken);

          setUser(result.data.signup.user);
          await Preferences.set({
            key: "authToken",
            value: result.data.signup.accessToken,
          });

          await present({
            message: "Du hast dich erfolgreich registriert!",
            color: "success",
            duration: 1500,
            position: "bottom",
          });

          // TODO: router push to profile with firstlogin
          router.push("/verify", "back", "push");
        }
      })
      .catch(async (error) => {
        setLoading(false);

        setStatus({
          ...status,
          general: error?.graphQLErrors[0]?.message,
        });

        if (
          error?.graphQLErrors[0]?.message ===
          "User with this email already exists"
        ) {
          await present({
            message: "Es existiert bereits ein Account mit dieser E-Mail!",
            color: "danger",
            duration: 1500,
            position: "bottom",
          });
          setStatus({
            ...status,
            general: "Es existiert bereits ein Account mit dieser E-Mail!",
          });
          return;
        }
        await present({
          message: "Die registrierung ist fehlgeschlagen !",
          color: "danger",
          duration: 1500,
          position: "bottom",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          {/*<IonButtons slot="start">*/}
          {/*  <IonBackButton defaultHref="/" />*/}
          {/*</IonButtons>*/}
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding-horizontal"
        style={{ marginTop: "2rem" }}
        fullscreen
      >
        <IonGrid className="main-grid" style={{ height: "auto" }}>
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" width={"54px"} alt="" />
          </IonRow>
        </IonGrid>
        <form onSubmit={formik.handleSubmit}>
          <div className="input-holder">
            <IonLabel className="input-label">Laborname</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="Labornamen eingeben"
                name={"laborname"}
                type="text"
                value={formik.values.laborname}
                onIonChange={formik.handleChange}
              />
              {!formik.errors.laborname && formik.values.laborname !== "" && (
                <IonIcon
                  className="verify-tick"
                  icon={checkmarkCircle}
                  slot="end"
                />
              )}
            </IonItem>
            {formik.errors?.laborname && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.laborname}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">Vorname</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="Vorname eingeben"
                name={"firstname"}
                type="text"
                value={formik.values.firstname}
                onIonChange={formik.handleChange}
              />
              {!formik.errors.firstname && formik.values.firstname !== "" && (
                <IonIcon
                  className="verify-tick"
                  icon={checkmarkCircle}
                  slot="end"
                />
              )}
            </IonItem>
            {formik.errors?.firstname && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.firstname}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">Nachname</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="Nachname eingeben"
                name={"lastname"}
                type="text"
                value={formik.values.lastname}
                onIonChange={formik.handleChange}
              />
              {!formik.errors.lastname && formik.values.lastname !== "" && (
                <IonIcon
                  className="verify-tick"
                  icon={checkmarkCircle}
                  slot="end"
                />
              )}
            </IonItem>
            {formik.errors?.lastname && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.lastname}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">E-Mail</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="E-Mail eingeben"
                name={"email"}
                type="text"
                value={formik.values.email}
                onIonChange={formik.handleChange}
              />
              {!formik.errors.email && formik.values.email !== "" && (
                <IonIcon
                  className="verify-tick"
                  icon={checkmarkCircle}
                  slot="end"
                />
              )}
            </IonItem>
            {formik.errors?.email && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.email}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">Passwort</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="Passwort eingeben"
                name={"password"}
                type={!showPassword ? "password" : "text"}
                value={formik.values.password}
                onIonChange={formik.handleChange}
              />
              <IonIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={!showPassword ? eye : eyeOff}
                slot="end"
              />
            </IonItem>
            {formik.errors?.password && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.password}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">Passwort wiederholen</IonLabel>
            <IonItem className="input-item">
              <IonInput
                className="form-input"
                placeholder="Passwort wiederholen"
                type={!showPassword ? "password" : "text"}
                name={"password_confirm"}
                value={formik.values.password_confirm}
                onIonChange={formik.handleChange}
              />
              <IonIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={!showPassword ? eye : eyeOff}
                slot="end"
              />
            </IonItem>
            {formik.errors?.password_confirm && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.password_confirm}</span>
              </IonInput>
            )}
          </div>

          <div className="input-holder">
            <IonLabel className="input-label">Geschlecht</IonLabel>
            <IonRadioGroup
              name={"gender"}
              onIonChange={(e) =>
                formik.setFieldValue("gender", e.detail.value)
              }
            >
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <div>Männlich</div>
                </IonLabel>
                <IonRadio slot="start" value="m"></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <div>Weiblich</div>
                </IonLabel>
                <IonRadio slot="start" value="f"></IonRadio>
              </IonItem>
            </IonRadioGroup>
            {formik.errors?.gender && (
              <IonInput className={"ion-padding"} color="danger">
                <span slot={"error"}>{formik.errors?.gender}</span>
              </IonInput>
            )}
          </div>

          <div className="btn-holder">
            <IonButton
              disabled={!formik.isValid || !formik.dirty}
              expand="block"
              className="login-button"
              type="submit"
            >
              {loading ? (
                <IonSpinner name="circular"></IonSpinner>
              ) : (
                "Registrieren"
              )}
            </IonButton>
          </div>
        </form>

        {formik.status?.general && (
          <IonInput color="danger">
            <span slot={"error"}>
              Die Registrierung ist fehlgeschlagen - {formik.status?.general}
            </span>
          </IonInput>
        )}

        <div
          style={{ marginBottom: "2rem" }}
          className="bottom-holder ion-padding-vertical flex al-center jc-center ion-text-center"
        >
          <p className="bottom-btn">Konto bereits vorhanden ?</p>
          <IonButton className="bottom-btn-span" routerLink="login">
            Einloggen
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
