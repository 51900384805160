import React, {FC} from "react";
import ReactApexChart from "react-apexcharts";
import {options} from "ionicons/icons";
import {type} from "os";

const pointsRadialChart = {
    series: [30],
    options: {
        chart: {
            type: 'radialBar',
            height: 40,
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1
        },
        labels: ["Erfolgsrate"],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '70%'
                },
                track: {
                    margin: 1
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -10,
                        show: false,
                        color: "#888",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 10,
                        color: "#111",
                        fontSize: "20px",
                        show: true
                    }
                }
            }
        },
        tooltip: {
            fixed: {
                enabled: false
            },
        }
    },
};

export const SuccessRate : FC<any> = ({ rate, fontSize = 20, width = 100, height = 100 }) => {

    const options = pointsRadialChart.options;
    options.plotOptions.radialBar.dataLabels.value.offsetY = fontSize / 2;
    options.plotOptions.radialBar.dataLabels.value.fontSize = fontSize + "px";

    return isNaN(rate) ? <h6>--</h6> : (<ReactApexChart
        style={{ display: "flex", justifyContent: "center" }}
        // @ts-ignore
        options={options}
        series={[rate]}
        type="radialBar"
        width={width}
        height={height}
    />)
};