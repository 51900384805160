import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { checkmarkCircle, eye, eyeOff } from "ionicons/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import "./Login.css";
import { useAuth } from "../../providers/auth";
import React, { FC, useContext, useEffect, useState } from "react";
import UIContext from "../../providers/UIProvider";

const Login: FC<any> = () => {
  const { setShowFab } = useContext(UIContext);
  const router = useIonRouter();

  useEffect(() => {
    setShowFab(false);
  }, []);

  const { signIn } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [present] = useIonToast();

  const initialValues = {
    email: "",
    password: "",
    laborname: "",
  };

  const validationSchema = yup.object({
    laborname: yup.string().trim().min(3),
    email: yup
      .string()
      .trim()
      .email("Bitte gib eine valide E-Mail Adresse ein.")
      .required("Bitte gib eine E-Mail Adresse ein."),
    password: yup
      .string()
      .required("Bitte gib dein Passwort ein")
      .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein"),
  });

  const onSubmit: any = (
    { email, password, laborname }: any,
    { setStatus, setErrors, errors, status }: any
  ) => {
    setLoading(true);
    setStatus({});
    signIn({ email, password, laborname })
      .then(async () => {
        setLoading(false);
        await present({
          message: "Du hast dich erfolgreich eingeloggt!",
          color: "success",
          duration: 1500,
          position: "bottom",
        });
      })
      .catch((err: any) => {
        setLoading(false);
        if (err?.graphQLErrors) {
          setStatus({
            ...status,
            general: err?.graphQLErrors[0]?.message,
          });
        } else {
          setStatus({
            ...status,
            general: err.message,
          });
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <IonPage>
      <IonHeader className="login-header ion-no-border">
        <IonToolbar>
          {/*<IonButtons slot="start">*/}
          {/*  <IonBackButton defaultHref="/" />*/}
          {/*</IonButtons>*/}
        </IonToolbar>
      </IonHeader>
      <IonContent className="login ion-padding-horizontal" fullscreen>
        <IonGrid className="main-grid" style={{ height: "auto" }}>
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <img src="/assets/imgs/logo/logo.webp" width={"54px"} alt="" />
          </IonRow>
        </IonGrid>
        <form onSubmit={formik.handleSubmit}>
          <div className="the-form">
            <div className="input-holder">
              <IonLabel className="input-label">Laborname</IonLabel>
              <IonItem fill="solid" className="input-item">
                <IonInput
                  className="form-input ion-valid"
                  placeholder="Labornamen eingeben"
                  name={"laborname"}
                  type="text"
                  value={formik.values.laborname}
                  onIonChange={formik.handleChange}
                />
                {formik.errors.laborname && (
                  <IonNote>{formik.errors.laborname}</IonNote>
                )}
                {!formik.errors.laborname && formik.values.laborname !== "" && (
                  <IonIcon
                    className="verify-tick"
                    icon={checkmarkCircle}
                    slot="end"
                  />
                )}
              </IonItem>
            </div>

            <div className="">
              <IonLabel className="input-label">E-Mail</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="E-Mail eingeben"
                  name={"email"}
                  type="text"
                  value={formik.values.email}
                  onIonChange={formik.handleChange}
                />
                {!formik.errors.email && formik.values.email !== "" && (
                  <IonIcon
                    className="verify-tick"
                    icon={checkmarkCircle}
                    slot="end"
                  />
                )}
              </IonItem>
            </div>

            <div className="input-holder">
              <IonLabel className="input-label">Passwort</IonLabel>
              <IonItem className="input-item">
                <IonInput
                  className="form-input"
                  placeholder="Passwort wiederholen"
                  name={"password"}
                  type={!showPassword ? "password" : "text"}
                  value={formik.values.password}
                  onIonChange={formik.handleChange}
                />
                <IonIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={!showPassword ? eye : eyeOff}
                  slot="end"
                />
                {formik.errors.password && (
                  <IonNote slot="error">{formik.errors.password}</IonNote>
                )}
              </IonItem>
            </div>
          </div>

          {formik.status?.general && (
            <IonInput color="danger">
              <span slot={"error"}>
                Der Benutzername, Laborname oder das Passwort ist ungültig !
              </span>
            </IonInput>
          )}

          <div className="rem-holder">
            <IonButton
              onClick={() => router.push("/password-reset-application")}
              className="forgot-button"
            >
              Passwort vergessen?
            </IonButton>
          </div>

          <div className="btn-holder">
            <IonButton
              expand="block"
              className="login-button"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <IonSpinner name="circular"></IonSpinner>
              ) : (
                "Einloggen"
              )}
            </IonButton>
          </div>
        </form>

        <div
          style={{ marginBottom: "2rem" }}
          className="bottom-holder ion-padding-vertical flex al-center jc-center ion-text-center"
        >
          <p className="bottom-btn">Noch kein Konto vorhanden ?</p>
          <IonButton className="bottom-btn-span" routerLink="/signup">
            Registrieren
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
