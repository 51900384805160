import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  FC,
  PropsWithChildren,
} from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  gql,
} from "@apollo/client";
import { GET_PROFILE } from "../schemas/profile";
import { Preferences } from "@capacitor/preferences";
import { Redirect } from "react-router-dom";
import { IonSpinner, useIonRouter } from "@ionic/react";
import UIContext from "./UIProvider";

// @ts-ignore
const authContext: any = createContext();

interface AuthProvider {
  pageProps: {
    protected: boolean;
    loggedOut?: boolean;
  };
}

export const AuthProvider: FC<PropsWithChildren<AuthProvider>> = ({
  pageProps,
  children,
}) => {
  const auth = useProvideAuth();

  useEffect(() => {
    (async () => {
      const { value: _authToken } = await Preferences.get({ key: "authToken" });
      const keys = await Preferences.keys();
    })();
  }, []);

  if (auth.loaded && pageProps.protected && !auth?.isSignedIn()) {
    return <Redirect to={"/login"} />;
  }

  if (auth.loaded && auth?.isSignedIn() && pageProps?.loggedOut) {
    // return <Redirect to={"/intro"} />;
    if (auth.user) {
      return auth.user?.verifiedAt ? (
        auth.user.introDoneAt ? (
          <Redirect to={"/app/profile"} />
        ) : (
          <Redirect to={"/intro"} />
        )
      ) : (
        <Redirect to={"/verify"} />
      );
    }
  }

  return (
    <authContext.Provider value={auth}>
      <ApolloProvider client={auth.createApolloClient()}>
        {auth.loaded ? children : <IonSpinner name="circular"></IonSpinner>}
      </ApolloProvider>
    </authContext.Provider>
  );
};

export const useAuth: any = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [authToken, setAuthToken] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  const { setRoleMode } = useContext(UIContext);
  const router = useIonRouter();

  useEffect(() => {
    if (authToken)
      (async () => {
        // set authentication from cookie
        const client = createApolloClient();
        try {
          const result = await client.query({ query: GET_PROFILE });
          setUser(result.data.me);
          setRoleMode(result?.data?.me?.role);
          setLoaded(true);
        } catch (e) {
          setLoaded(true);
          signOut();
        }
      })();
  }, [authToken]);

  useEffect(() => {
    const setTokenFromPreferences = async () => {
      const { value: _authToken } = await Preferences.get({ key: "authToken" });

      if (_authToken) {
        setAuthToken(_authToken);
      }
      setLoaded(true);
    };
    setTokenFromPreferences();
  }, []);

  const reloadUser = async () => {
    const client = createApolloClient();
    const result = await client.query({ query: GET_PROFILE });
    setUser(result.data.me);
    setRoleMode(result?.data?.me?.role);
    return result.data.me;
  };

  const authTokenGet = async () => {
    const { value: _authToken } = await Preferences.get({ key: "authToken" });
    return _authToken;
  };

  const isSignedIn = () => {
    if (authToken) {
      return true;
    }
    return false;
  };

  const getAuthHeaders = () => {
    return {
      authorization: `Bearer ${authToken}`,
    };
  };

  const createApolloClient = () => {
    const link = new HttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      headers: getAuthHeaders(),
    });

    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  };

  const signIn = async (
    { email, password, laborname }: any,
    keepLoggedIn = false
  ) => {
    setLoaded(false);
    const client = createApolloClient();
    const LoginMutation = gql`
      mutation signin(
        $email: String!
        $password: String!
        $laborname: String!
      ) {
        login(
          data: { email: $email, password: $password, laborname: $laborname }
        ) {
          accessToken
          refreshToken
          user {
            id
            email
            email
            firstname
            lastname
            laborname
            gender
            role
            verifiedAt
            introDoneAt
          }
        }
      }
    `;

    setLoaded(true);

    const result = await client.mutate({
      mutation: LoginMutation,
      variables: { email, password, laborname },
    });

    if (result?.data?.login?.accessToken) {
      setAuthToken(result.data.login.accessToken);

      setRoleMode(result?.data?.login?.user.role);
      setUser(result.data.login.user);
      await Preferences.set({
        key: "authToken",
        value: result.data.login.accessToken,
      });
    }
    setLoaded(false);
  };

  const signOut = async () => {
    setAuthToken(null);
    setUser(null);
    setRoleMode("USER");
    await Preferences.remove({ key: "authToken" });
  };

  return {
    setAuthToken,
    setUser,
    authToken,
    authTokenGet,
    isSignedIn,
    signIn,
    loaded,
    user,
    reloadUser,
    signOut,
    createApolloClient,
  };
}
