import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonButton, IonModal, useIonToast } from "@ionic/react";
import { User, useUsers } from "../../hooks/useUsers";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css";
import { Icon } from "@iconify/react";
import { DeleteUser } from "../Modals/DeleteUser";
import { VerifyUser } from "../Modals/VerifyUser";
import { EditRole } from "../Modals/EditRole";
import { useMutation } from "@apollo/client";
import {
  RESEND_USER_VERIFICATION,
  VERIFY_USER,
} from "../../../../schemas/users";

export const UserManagement: FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [activeUser, setActiveUser] = useState<User | null>(null);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [verifyUserModalOpen, setVerifyUserModalOpen] = useState(false);
  const { users, loading, refetch } = useUsers();

  const [resendUserVerificationMutation, { error }] = useMutation(
    RESEND_USER_VERIFICATION
  );
  const [present] = useIonToast();

  const resendUserVerification = (userId: string) => {
    // verify user mutation
    resendUserVerificationMutation({ variables: { userId } }).then(async () => {
      await present({
        message: "Du hast die Verifizierungsmail erfolgreich erneut gesendet!",
        color: "success",
        duration: 1500,
        position: "bottom",
      });
    });
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "firstname",
      width: 130,
      headerName: "Vorname",
      suppressStickyLabel: true,
      filter: true,
    },
    {
      field: "lastname",
      width: 130,
      pinned: true,
      headerName: "Nachname",
      suppressStickyLabel: true,
      filter: true,
    },
    {
      field: "laborname",
      width: 180,
      headerName: "Laborname",
      suppressStickyLabel: true,
      filter: true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      suppressStickyLabel: true,
      filter: true,
      valueFormatter: (params: any) => {
        return params.value;
      },
    },
    {
      suppressStickyLabel: true,
      field: "verifiedAt",
      width: 120,
      headerName: "Verifiziert",
      valueFormatter: (params: any) => (params.value ? "Ja" : "Nein"),
    },
    {
      field: "role",
      width: 180,
      headerName: "Rolle",
      suppressStickyLabel: true,
      valueFormatter: (params: any) =>
        params.value === "ADMIN" ? "Admin" : "User",
    },
    {
      field: "id",
      headerName: "Aktionen",
      position: "left",
      width: 220,
      cellRenderer: (params: any) => {
        return (
          <div className="actions">
            <IonButton
              color={"secondary"}
              onClick={() => {
                openEditRole(params.data);
              }}
            >
              <Icon
                width={"12px"}
                height={"12px"}
                icon="eos-icons:role-binding-outlined"
              />
            </IonButton>
            <IonButton
              disabled={params.data.verifiedAt ? true : false}
              color={"success"}
              onClick={() => {
                if (params.data.verifiedAt) return;
                openVerifyUser(params.data);
              }}
            >
              <Icon width={"18px"} height={"18px"} icon="ph:check-bold" />
            </IonButton>
            <IonButton
              disabled={params.data.verifiedAt ? true : false}
              color={"secondary"}
              onClick={() => {
                if (params.data.verifiedAt) return;
                resendUserVerification(params.data.id);
              }}
            >
              <Icon
                width={"18px"}
                height={"18px"}
                icon="material-symbols:mail"
              />
            </IonButton>
            <IonButton
              color={"danger"}
              onClick={() => {
                openDeleteUser(params.data);
              }}
            >
              <Icon width={"18px"} height={"18px"} icon="mdi:trash-outline" />
            </IonButton>
          </div>
        );
      },
    },
  ]);

  const openEditRole = (user: User) => {
    setActiveUser(user);
    setEditRoleModalOpen(true);
  };

  const openDeleteUser = (user: User) => {
    setActiveUser(user);
    setDeleteUserModalOpen(true);
  };

  const openVerifyUser = (user: User) => {
    setActiveUser(user);
    setVerifyUserModalOpen(true);
  };

  const resendVerificationUser = (user: User) => {};

  const gridRef = useRef();

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const cellClickedListener = useCallback((event: any) => {
    // console.log("cellClicked", event);
  }, []);

  useEffect(() => {
    setRowData(users);
  }, [users]);

  const buttonListener = useCallback((e: any) => {
    // gridRef?.current?.api?.deselectAll();
  }, []);

  return (
    <>
      <div
        className="ag-theme-material"
        style={{ height: "90%", width: "100%" }}
      >
        <AgGridReact
          pagination
          paginationAutoPageSize
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
        />
      </div>
      <IonModal
        isOpen={deleteUserModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => setDeleteUserModalOpen(false)}
      >
        <DeleteUser
          user={activeUser}
          onClose={async () => {
            await refetch();
            setDeleteUserModalOpen(false);
          }}
        />
      </IonModal>
      <IonModal
        isOpen={verifyUserModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => setVerifyUserModalOpen(false)}
      >
        <VerifyUser
          user={activeUser}
          onClose={async () => {
            await refetch();
            setVerifyUserModalOpen(false);
          }}
        />
      </IonModal>
      <IonModal
        isOpen={editRoleModalOpen}
        class="modaaal"
        className="modaaal"
        onDidDismiss={() => setEditRoleModalOpen(false)}
      >
        <EditRole
          user={activeUser}
          onClose={async () => {
            await refetch();
            setEditRoleModalOpen(false);
          }}
        />
      </IonModal>
    </>
  );
};
