import React, { FC, useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { User } from "../../hooks/useUsers";
import { Icon } from "@iconify/react";
import { useMutation } from "@apollo/client";
import { EDIT_ROLE } from "../../../../schemas/users";

export const EditRole: FC<{ onClose: () => void; user: User | null }> = ({
  onClose,
  user,
}) => {
  const [editRoleMutation, { error }] = useMutation(EDIT_ROLE);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [present] = useIonToast();

  useEffect(() => {
    setSelectedRole(user?.role);
  }, [user]);

  const editRole = () => {
    setLoading(true);
    // edit user mutation
    editRoleMutation({
      variables: { userId: user?.id, role: selectedRole },
    }).then(async () => {
      setLoading(false);
      await present({
        message: "Du hast den Benutzer erfolgreich geändert!",
        color: "success",
        duration: 1500,
        position: "bottom",
      });
      onClose();
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen className="modaaal">
        <IonGrid className="main-grid">
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <div className="the-holder ion-padding">
              <IonRow className="title-row">
                <IonCol
                  size="12"
                  class="ion-text-right"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <p>abbrechen</p>
                </IonCol>
              </IonRow>
              <h4 className={"ion-justify-content-center ion-text-center"}>
                Bist du dir sicher die Rolle des Benutzers abzuändern ?
              </h4>
              <p className={"ion-text-center"}>
                <b>Benutzer:</b> {user?.email}
              </p>
              <IonRow
                className={
                  "ion-justify-content-center ion-padding ion-color-secondary"
                }
              >
                <Icon
                  width={"84px"}
                  height={"84px"}
                  icon="eos-icons:role-binding-outlined"
                />
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-padding"}>
                <IonItem style={{ width: "200px" }}>
                  <IonSelect
                    className={"ion-justify-content-center"}
                    style={{ width: "200px" }}
                    onIonChange={(e) => {
                      setSelectedRole(e.detail.value);
                    }}
                    aria-label="Rolle"
                    value={selectedRole}
                  >
                    <IonSelectOption value="ADMIN">Admin</IonSelectOption>
                    <IonSelectOption value="USER">User</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-padding"}>
                <IonButton onClick={editRole} disabled={loading}>
                  {loading ? (
                    <IonSpinner name="circular"></IonSpinner>
                  ) : (
                    "Speichern"
                  )}
                </IonButton>
              </IonRow>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
