import React from "react";
import {IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonSpinner} from "@ionic/react";

export const EmptyContent = ({ title = 'Für diesen Bereich existieren momentan keine oder zu wenige Daten', cta = false, ctaDescription = '', ctaText = "Aktion", ctaCallback = () => {} }) => {
    return <IonCard>
        <IonCardHeader className={'ion-text-center'}>
            <IonCardSubtitle>{title}</IonCardSubtitle>
            <img src={'/assets/illustration_empty_content.svg'} alt=""/>
            {cta && <><p>
                {ctaDescription}
            </p>
            <div className="btn-holder" onClick={ctaCallback}>
                <IonButton expand="block">
                    {ctaText}
                </IonButton>
            </div></>}
        </IonCardHeader>
    </IonCard>
};