import { gql } from "@apollo/client";
export const SIGNUP = gql`
  mutation signup(
    $laborname: String!
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $password_confirm: String!
    $gender: String!
  ) {
    signup(
      data: {
        laborname: $laborname
        email: $email
        firstname: $firstname
        lastname: $lastname
        password: $password
        password_confirm: $password_confirm
        gender: $gender
      }
    ) {
      accessToken
      user {
        id
        email
        firstname
        lastname
        gender
        role
        gender
        verifiedAt
      }
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationMail {
    resendVerificationMail {
      id
    }
  }
`;

export const VERIFY = gql`
  mutation verify($token: String!) {
    verify(data: { token: $token }) {
      id
    }
  }
`;

export const PASSWORD_RESET_APPLICATION = gql`
  mutation passwordResetApplication($email: String, $laborname: String) {
    passwordResetApplication(data: { email: $email, laborname: $laborname }) {
      id
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation passwordReset($token: String!, $password: String!) {
    passwordReset(data: { token: $token, password: $password }) {
      id
    }
  }
`;
