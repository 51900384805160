import React, {FC, useState} from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonModal} from '@ionic/react';
import ReactApexChart from "react-apexcharts";
import {SuccessRate} from "../../../components/charts/SuccessRate";
import {EmptyContent} from "../../../components/EmptyContent";
import {NewConversationModal} from "../../Modals/NewConversationModal";

const pointsRadialChart = {
    series: [30],
    options: {
        chart: {
            type: 'radialBar',
            height: 40,
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1
        },
        labels: ["Erfolgsrate"],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '70%'
                },
                track: {
                    margin: 1
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -10,
                        show: false,
                        color: "#888",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 10,
                        color: "#111",
                        fontSize: "20px",
                        show: true
                    }
                }
            }
        },
        tooltip: {
            fixed: {
                enabled: false
            },
        }
    },
};

export const WidgetSuccessRate: FC<any> = ({ metricsData, segment }) => {

    const [newConversationModalOpen, setNewConversationModalOpen] = useState(false);

    if (isNaN(metricsData?.successRate)) return <><EmptyContent
        title={"Für diesen Bereich stehen noch keine Daten bereit"}
        cta={true}
        ctaText={"Gespräch beginnen"}
        ctaDescription={"Möchtest du ein neues Gespräch mit einer Zahnarzthelferin führen ?"}
        ctaCallback={() => { setNewConversationModalOpen(true); }} />
        <IonModal isOpen={newConversationModalOpen} class="modaaal" className="modaaal" onDidDismiss={() => setNewConversationModalOpen(false)}>
            <NewConversationModal onClose={() => setNewConversationModalOpen(false)} />
        </IonModal>
    </>;

    return  (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle><h6>Deine Erfolgsrate</h6></IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <SuccessRate rate={metricsData.successRate} />
            </IonCardContent>
        </IonCard>
    );
};