import React, {FC, useState} from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonModal} from '@ionic/react';
import ReactApexChart from "react-apexcharts";

 import de from "apexcharts/dist/locales/de.json"
import {EmptyContent} from "../../../components/EmptyContent";
import {NewConversationModal} from "../../Modals/NewConversationModal";

export const WidgetPointsHistory: FC<any> = ({ metricsData }) => {

    const [newConversationModalOpen, setNewConversationModalOpen] = useState(false);

    const pointsHistorySparkChart = {
        options: {
            chart: {
                locales: [de],
                defaultLocale: 'de',
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: '',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3'],
                    opacity: 0.5
                },
            },
            xaxis: {
                type: 'datetime'
            }
        }
    };

    const series = metricsData ? [
        { type: 'column', name: "Punktzahl", data: metricsData?.timeSeries.map((dataPoint: any) => ({ x: new Date(dataPoint.createdAt).getTime(), y: dataPoint.points }) )},
        { type: 'area', name: "Mögliche Punktzahl", data: metricsData?.timeSeries.map((dataPoint: any) => ({ x: new Date(dataPoint.createdAt).getTime(), y: dataPoint.availablePoints }) )},
        { type: 'line', name: "Mindest Punktzahl", data: metricsData?.timeSeries.map((dataPoint: any) => ({ x: new Date(dataPoint.createdAt).getTime(), y: dataPoint.minimumPoints }) )},
    ] : [];

    if (!metricsData?.timeSeries.length) return <><EmptyContent
        title={"Für diesen Bereich stehen noch keine Daten bereit"}
        cta={true}
        ctaText={"Gespräch beginnen"}
        ctaDescription={"Möchtest du ein neues Gespräch mit einer Zahnarzthelferin führen ?"}
        ctaCallback={() => { setNewConversationModalOpen(true); }} />
    <IonModal isOpen={newConversationModalOpen} class="modaaal" className="modaaal" onDidDismiss={() => setNewConversationModalOpen(false)}>
        <NewConversationModal onClose={() => setNewConversationModalOpen(false)} />
    </IonModal>
</>;

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle><h6>Punkte Historie</h6></IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                {/* @ts-ignore */}
                <ReactApexChart options={pointsHistorySparkChart.options} series={series} type="area" height={210} />
            </IonCardContent>
        </IonCard>
    );
};