import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
  useIonRouter,
} from "@ionic/react";
import "./NewConversationModal.scss";
import { FC, useEffect, useState } from "react";
import leads from "../../../_mocks/leads.json";

export const NewConversationModal: FC<{ onClose?: any }> = ({ onClose }) => {
  const [started, setStarted] = useState<boolean>(false);
  const [currentLead, setCurrentLead] = useState<any>(false);

  const router = useIonRouter();

  useEffect(() => {
    if (started) {
      setTimeout(() => {
        onClose();
      }, 4500);
      setTimeout(() => {
        router.push("/akquisition-call", "back", "push");
      }, 5000);
      setInterval(() => {
        setCurrentLead(leads[randomIntFromInterval(0, leads.length)]);
      }, 1000);
    }
  }, [started]);

  const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="modaaal">
        <IonGrid className="main-grid">
          <IonRow className="main-row ion-justify-content-center ion-align-items-center">
            <div className="the-holder ion-padding">
              <IonRow className="title-row">
                <IonCol
                  size="12"
                  class="ion-text-right"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <p>abbrechen</p>
                </IonCol>
              </IonRow>
              <IonRow className={"ion-justify-content-center ion-padding"}>
                <img src="/assets/imgs/car.svg" alt="" />
              </IonRow>
              {!started && (
                <IonRow
                  className={
                    "ion-padding-start ion-justify-content-center ion-text-center"
                  }
                >
                  <p>Beginne ein neues Gespräch mit einer Zahnarzthelferin</p>
                  <IonButton
                    onClick={() => {
                      setStarted(true);
                    }}
                  >
                    Beginnen
                  </IonButton>
                </IonRow>
              )}
              {started && (
                <IonRow
                  className={
                    "ion-padding-start ion-justify-content-center ion-text-center"
                  }
                >
                  <p>Die Zahnarztpraxis wird angefahren</p>
                  <br />
                  <IonButton disabled>
                    <IonSpinner name="crescent" />
                  </IonButton>
                </IonRow>
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
